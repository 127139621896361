import { render, staticRenderFns } from "./Section.vue?vue&type=template&id=51302595&scoped=true"
import script from "./Section.vue?vue&type=script&lang=js"
export * from "./Section.vue?vue&type=script&lang=js"
import style0 from "./Section.vue?vue&type=style&index=0&id=51302595&prod&lang=scss"
import style1 from "./Section.vue?vue&type=style&index=1&id=51302595&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../BuildingCatalog/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51302595",
  null
  
)

export default component.exports